import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ApsAttributes} from "../models/aps-attributes";
import {Config} from "../config";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApsService {
	private config = new Config();
	private serviceUrl = `${this.config.url}`;
  constructor(private http: HttpClient) { }

	retrieveAttributes(): Observable<ApsAttributes> {
		return this.http.get<ApsAttributes>(this.serviceUrl + '/aps/retrieveAttributes');
	}
}
