import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Config} from "../config";
import {Observable} from "rxjs";
import {PpapPhaseSection} from "../models/ppap-phase-section";

@Injectable({
  providedIn: 'root'
})
export class PpapPhaseSectionService {
	private config = new Config();
	private httpUrlPpapPhase = `${this.config.url}/epsw/ppapPhase`;
	constructor(private http: HttpClient) { }

	getAllData(){
		return this.http.get<any[]>(this.httpUrlPpapPhase);
	}

	saveData(ppapPhaseData: PpapPhaseSection): Observable<any> {
		return this.http.post<any>(this.httpUrlPpapPhase+'/saveSections', ppapPhaseData);
	}

	updateData(ppapSectionLoadedData:any): Observable<any> {
		return this.http.put<any>(this.httpUrlPpapPhase+'/updateSections/'+ppapSectionLoadedData.sectionId, ppapSectionLoadedData);
	}

	deleteData(sectionId: number){
		return this.http.delete<any>(this.httpUrlPpapPhase+'/deleteSection/'+sectionId,{responseType:'json'});
	}
}
