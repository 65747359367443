import {PpapPackageParts} from './ppap-package-parts';
import {DropdownOption} from './dropdown-option';

export  class PpapPackageIdentifier {
	ppapPackageIdentifierID : number
	siteCode : DropdownOption
	ppapPhase : string
	ppapPhaseCode : string
	ppapPhasePlan : string
	commodityImg : FormData
	pdApprovalRequired : string
	pdApproverCdsid:string;
	staApproverCdsid:string;
	pdApproverName:string;
	staApproverName:string;
	pdApproverEmailId:string;
	staApproverEmailId:string;
	adhocDocument:string;
	lastUpdatedBy : string
	lastUpdatedOn : Date
	createdBy: string;
	createdOn: Date;
	familyPriorityStatus: string;
	ppapPackagePartsEntity : PpapPackageParts[];
	addOrUpdateParts : boolean;
	familyPrioritySavedFlag : string;
	staSiteEngineerCdsId : string;
	staSiteEngineerSuperVisorCdsId : string;
	staSiteManagerCdsId : string;

	constructor(ppapPackageIdentifier: PpapPackageIdentifier) {
		Object.assign(this, ppapPackageIdentifier);
	}
}
