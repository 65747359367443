export class AllPPAPPhase {
	phase0Next15Days: number;
	phase1Next15Days: number;
	phase2Next15Days: number;
	phase3Next15Days: number;
	phase0PlanSubmission: number;
	phase1PlanSubmission: number;
	phase2PlanSubmission: number;
	phase3PlanSubmission: number;
	phase0SubOrApprovalPending: number;
	phase1SubOrApprovalPending: number;
	phase2SubOrApprovalPending: number;
	phase3SubOrApprovalPending: number;
	phase0SupplierCausedSlip: number;
	phase1SupplierCausedSlip: number;
	phase2SupplierCausedSlip: number;
	phase3SupplierCausedSlip: number;
	phase0PDCausedSlip: number;
	phase1PDCausedSlip: number;
	phase2PDCausedSlip: number;
	phase3PDCausedSlip: number;
	phase0PurchaseSlip: number;
	phase1PurchaseSlip: number;
	phase2PurchaseSlip: number;
	phase3PurchaseSlip: number;
	phase0OtherSlips: number;
	phase1OtherSlips: number;
	phase2OtherSlips: number;
	phase3OtherSlips: number;
	phase0RejectedPSW: number;
	phase1RejectedPSW: number;
	phase2RejectedPSW: number;
	phase3RejectedPSW: number;
	phase0ApprovedPSW: number;
	phase1ApprovedPSW: number;
	phase2ApprovedPSW: number;
	phase3ApprovedPSW: number;
	//Added for US3832060
	phase0NoActionRequiredPSW: number;
	phase1NoActionRequiredPSW: number;
	phase2NoActionRequiredPSW: number;
	phase3NoActionRequiredPSW: number;

	phase0AwaitingMyApprovalSTA: number;
	phase1AwaitingMyApprovalSTA: number;
	phase2AwaitingMyApprovalSTA: number;
	phase3AwaitingMyApprovalSTA: number;
	phase0AwaitingMyApprovalPD: number;
	phase1AwaitingMyApprovalPD: number;
	phase2AwaitingMyApprovalPD: number;
	phase3AwaitingMyApprovalPD: number;

	constructor( allPPAPPhase : AllPPAPPhase ) {
		Object.assign(this,allPPAPPhase);
	}
}
