export class CopyDataList {
	digitalPpapValues: string;
	elementCheckboxValues: string;
	// noPartPrefix:any;
	// noPartBase:string;
	// noPartSuffix:string;

	constructor(copyDataList: CopyDataList) {
		Object.assign(this, CopyDataList);
	}
}
