import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
	private accessAndTrainingQALink = 'https://www.google.com/';
	private fAQsLink = 'https://www.lean.ford.com/File/ViewFile?fileName=Cqdc%2FeCAR%20Frequently%20Asked%20Questions.pdf';

	constructor() {
	}

	ngOnInit() {
		console.log('Testing');
	}

}
