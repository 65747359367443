import { Injectable } from '@angular/core';
import {Message, MessageService} from 'primeng';
import {HttpErrorResponse} from '@angular/common/http';
import {EMPTY} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HttpErrorHandlerService {

	constructor(private messageService: MessageService) {
	}

	handleError(errorResponse: HttpErrorResponse, errorMessage?: Message) {
		if (errorResponse.error instanceof ErrorEvent) {
			console.error('An error occurred:', errorResponse.error.message);
		} else {
			const consoleError = this.validateErrorMessage(errorResponse);
			console.error(consoleError);
		}

		if (errorMessage) {
			this.messageService.add(errorMessage);
		}

		return EMPTY;
	}

	private validateErrorMessage(errorResponse: HttpErrorResponse) {
		let consoleError = `Backend returned code ${errorResponse.status}`;
		if (errorResponse.error && errorResponse.error.error) {
			consoleError += `, body was: ${errorResponse.error.error.messages}`;
		}
		return consoleError;
	}
}
