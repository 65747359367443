/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */
export const environment = {
	envName: 'qa',
	production: false,
	tokenUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	clientId: 'urn:epsw_prepord:clientid:web_epsw_preprod:qa',
	resource: 'urn:epsw_prepord:resource:web_epsw_preprod:qa',
	// apiUrl: 'https://purssat-psw-qa.apps.pd01e.edc1.cf.ford.com/api/v1/psw'
	// apiUrl: 'https://purssat-psw-qa.apps.pd01e.edc2.cf.ford.com/api/v1/psw'
	apiUrl: 'https://wwwqa.api.epsw.ford.com/api/v1/psw'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
