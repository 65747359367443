import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SpinnerService {

	spinner: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);

	familySpinner: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);

	constructor() {
		this.spinner = new BehaviorSubject(false);
		this.familySpinner = new BehaviorSubject(false);
	}

	show() {
		this.spinner.next(true);
	}
	showFamilySpinner() {
		this.familySpinner.next(true);
	}

	hide() {
		this.spinner.next(false);
	}

	hideFamilySpinner() {
		this.familySpinner.next(false);
	}
}
