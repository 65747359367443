export class PpapPackageParts {
	ppapPackagePartsEntityID : number
	ppapPackageIdentifierID : number
	programCode : string
	noPartPrefix : string
	noPartBase : string
	noPartSuffix : string
	partDescription : string
	rarDt : Date
	qualDt : Date
	prodDt : Date
	capDt : Date
	status : string
	ppapLevel : string
	select : string
	noLstNotice : string
	dtLstNotice : Date
	nextAuditLayout: string;
	isFormCompleted: string;
	submittedEmailId: string
	submittedCdsid: string
	submittedBy: string
	submittedOn: Date
	ppapStaStatus: string
	ppapStaStatusBy: string
	ppapStaStatusOn: Date
	ppapStaComments: string
	ppapPdStatus: string
	ppapPdStatusBy: string
	ppapPdStatusOn: Date
	ppapPdComments: string
	overallPpapStatus: string
	pswStaStatus: string
	pswStaStatusBy: string
	pswStaStatusOn: Date
	pswStaComments: string
	pswPdStatus: string
	pswPdStatusBy: string
	pswPdStatusOn: Date
	pswPdComments: string
	overallPswStatus: string
	partNumber: string
	phase1Phase2 : any;
	pswStaUserId: string
	pswPdUserId : string
	programName : string
	naComments : string
	lastUpdatedBy : string
	lastUpdatedOn : Date
	createdBy: string
	createdOn: Date
	//Added for US3853075
	familyPswId: number
	familyPswOrgPartNumber: string;
	familyPswPoNumber: string;
	familyPswWeight: string;
	familyPswIMDSIDNo: string;
	familyPswOptional1: string
	familyPswOptional2: string
	familyPswOptional3: string
	familyPswOptional4: string
	familyPswOptional5: string
	recallComments: string
	recallDate:  Date
	recallFlow: string
	recallBy : string
	staApprovalRequired: string
	priorityStatus: string
	cdSupplierShpFr: string
	programDescription: string
	constructor(ppapPackageParts: PpapPackageParts[]) {
		Object.assign(this, ppapPackageParts);
	}
}
