import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class RoleBasedAccessProviderService {
	private static matrixDisplayUpdate: boolean;
	private static selectCheckBoxVisibility: boolean;
	private static supplierAccess: boolean;
	private static displayPpapPackageNextBtn: boolean;
	private static displayPpapPackageSubmitBtn: boolean;
	private static staApprovalConditionsEnabled: boolean;
	private static pdApprovalConditionsEnabled: boolean;
	private static approverConditionEnabled: boolean;
	private static isSupplier: boolean;
	private static isPDEngineer: boolean;
	private static roleBasedAccess: Map<string, any> = new Map<string, any>();
	private static roleBasedAccessForApprover: Map<string, any> = new Map<string, any>();
	private static programEnabled: boolean;

	constructor() {
	}

	static getAccessForSupplierRole(userRole: string) {
		console.log('#### User Role : ' + userRole);
		if (userRole === 'EAPQP_UPDATE' || userRole === 'EAPQP_READ' || userRole === 'PDEngineer') {
			this.matrixDisplayUpdate = false;
			if (userRole === 'PDEngineer') {
				this.isPDEngineer = true;
			} else {
				this.isPDEngineer = false;
			}

			if (userRole === 'EAPQP_UPDATE') {
				this.selectCheckBoxVisibility = true;
				this.supplierAccess = true;
			} else {
				this.selectCheckBoxVisibility = false;
				this.supplierAccess = false;
			}
			if (userRole === 'EAPQP_UPDATE' || userRole === 'EAPQP_READ') {
				this.isSupplier = true;
			} else {
				this.isSupplier = false;
			}
		} else {
			this.matrixDisplayUpdate = true;
			this.supplierAccess = false;
			this.selectCheckBoxVisibility = false;
			this.isPDEngineer = false;
		}
		if (userRole === 'STASrProgramManager' || userRole === 'STAProgramEngineer' || userRole === 'STAProgramManager'
			|| userRole === 'ePSW Admin' || userRole === 'PDEngineer' || userRole === 'STASiteEngineer' ||
			userRole === 'STASiteSupervisor' || userRole === 'STASiteManager' || userRole === 'STASuperUser'
		|| userRole === 'APQPSiteSTAEngineer') {
			this.programEnabled = true;
		} else {
			this.programEnabled = false;
		}
		this.roleBasedAccess.set('matrixDisplayUpdate', this.matrixDisplayUpdate);
		this.roleBasedAccess.set('selectCheckBoxVisibility', this.selectCheckBoxVisibility);
		this.roleBasedAccess.set('supplierAccess', this.supplierAccess);
		this.roleBasedAccess.set('isSupplier', this.isSupplier);
		this.roleBasedAccess.set('isPDEngineer', this.isPDEngineer);
		this.roleBasedAccess.set('programEnabled', this.programEnabled);
		return this.roleBasedAccess;
	}

	static getPhaseBasedAccessForSupplier(userRole: any, ppapPhase: string) {
		if (userRole === 'EAPQP_UPDATE') {
			if (ppapPhase === 'Phase0') {
				this.displayPpapPackageNextBtn = false;
				this.displayPpapPackageSubmitBtn = true;
			} else {
				this.displayPpapPackageSubmitBtn = false;
				this.displayPpapPackageNextBtn = true;
			}
		} else {
			if (ppapPhase === 'Phase0') {
				this.displayPpapPackageNextBtn = false;
				this.displayPpapPackageSubmitBtn = true;
			} else {
				this.displayPpapPackageSubmitBtn = false;
				this.displayPpapPackageNextBtn = true;
			}
		}
		this.roleBasedAccess.set('displayPpapPackageNextBtn', this.displayPpapPackageNextBtn);
		this.roleBasedAccess.set('displayPpapPackageSubmitBtn', this.displayPpapPackageSubmitBtn);
		return this.roleBasedAccess;
	}

	static getPhaseBasedAccessForApprover(userRole: any, isApprover: boolean) {
		if (isApprover) {
			this.approverConditionEnabled = true;
			if (userRole === 'STASiteEngineer' || userRole === 'APQPSiteSTAEngineer' || userRole === 'STASiteSupervisor' || userRole === 'STASiteManager') {
				this.staApprovalConditionsEnabled = true;
				this.pdApprovalConditionsEnabled = false;
			} else if (userRole === 'PDEngineer') {
				this.pdApprovalConditionsEnabled = true;
				this.staApprovalConditionsEnabled = false;
			} else {
				this.pdApprovalConditionsEnabled = false;
				this.staApprovalConditionsEnabled = false;
			}
		} else {
			this.approverConditionEnabled = false;
		}
		this.roleBasedAccessForApprover.set('staApprovalConditionsEnabled', this.staApprovalConditionsEnabled);
		this.roleBasedAccessForApprover.set('pdApprovalConditionsEnabled', this.pdApprovalConditionsEnabled);
		this.roleBasedAccessForApprover.set('approverConditionEnabled', this.approverConditionEnabled);
		return this.roleBasedAccessForApprover;
	}
}
