export class SubSupplierPpapPackage {
	subSupplierPpapPackageID: number
	subSupplierCategory: string
	subSupplierName: string
	partDescription: string
	attachment: string
	status: string
	createdBy: string
	createdOn: Date
	lastUpdatedBy: string
	lastUpdatedOn: Date

	constructor(subSupplierPpapPackage: SubSupplierPpapPackage) {
		Object.assign(this, subSupplierPpapPackage);
	}
}
