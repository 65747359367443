import {Component, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';

interface DesignRecords {
	id: string;
	ppap: string;
	asstppap: string;
	quest: string;
	status: string;
	docSubmitted: string;
	proprietary: string;
	supplier: string;
	rejected: string;
	pdremarks: string;
	updatebyname: string;
	updatebydate: string;
}

@Component({
	selector: 'ppap-elements',
	templateUrl: './ppap-elements.component.html',
	styleUrls: ['./ppap-elements.component.scss']
})

export class PpapElementsComponent implements OnInit {

	designRecord: DesignRecords[];
	statuses: SelectItem[];
	clonedProducts: { [s: string]: DesignRecords; } = {};
	question: SelectItem[];
	rejected: SelectItem[];
	proprietary: SelectItem[];
	newAttribute: any = {};

	deleteRow(x) {
		console.log(x);
		//var delBtn = confirm(" Do you want to delete ?");
		//if (delBtn === true) {
		this.designRecord.splice(x, 1);
		//}
	}

	edit(testCases, index) {

		//this.selectedTestCases=rowdata
		this.designRecord.findIndex(index);
	}

	addFieldValue() {
		this.designRecord.unshift(this.newAttribute);
		this.newAttribute = {
			id: '0',
			ppap: '',
			asstppap: '',
			quest: '',
			status: '',
			docSubmitted: '',
			proprietary: '',
			supplier: '',
			rejected: '',
			pdremarks: ''
		};
		//this.designRecord.indexOf(0).e
		//this.designRecord.initRowEdit(this.newAttribute);
	}

	onSave() {
		console.log(this.designRecord);
	}

	constructor() {
		this.newAttribute = {
			id: '0',
			ppap: '',
			asstppap: '',
			quest: '',
			status: '',
			docSubmitted: '',
			proprietary: '',
			supplier: '',
			rejected: '',
			pdremarks: ''
		};
	}

	ngOnInit(): void {

		this.designRecord = [
			{
				id: '1',
				ppap: '1',
				asstppap: 'Is the part released in WERS?',
				quest: '',
				status: 'OK',
				docSubmitted: '',
				proprietary: '',
				supplier: '',
				rejected: '',
				pdremarks: '',
				updatebyname: 'Parthipan Murali',
				updatebydate: '07/07/2021'
			},
			{
				id: '2',
				ppap: '2',
				asstppap: 'Do you have a copy of the 3D-CAD model or 2D-print?',
				quest: '',
				status: '-/-',
				docSubmitted: '',
				proprietary: '',
				supplier: '',
				rejected: '',
				pdremarks: '',
				updatebyname: 'Parthipan Murali',
				updatebydate: '07/07/2021'
			},
			{
				id: '3',
				ppap: '3',
				asstppap: 'Do you have a copy of all engineering/ material/ performance specifications listed on the drawing?',
				quest: '',
				status: '-/-',
				docSubmitted: '',
				proprietary: '',
				supplier: '',
				rejected: '',
				pdremarks: '',
				updatebyname: 'Parthipan Murali',
				updatebydate: '07/07/2021'
			},
			{
				id: '4',
				ppap: '4',
				asstppap: 'Supporting product data shall comply with FECDS (CAD & drafting standards)',
				quest: '',
				status: '-/-',
				docSubmitted: '',
				proprietary: '',
				supplier: '',
				rejected: '',
				pdremarks: '',
				updatebyname: 'Parthipan Murali',
				updatebydate: '07/07/2021'
			}
		]

		this.statuses = [{label: '-/-', value: ''}, {label: 'N/A', value: 'N/A'}, {
			label: 'OK',
			value: 'OK'
		}, {label: 'NOK', value: 'NOK'}]
		this.question = [{label: '-/-', value: '-/-'}, {label: 'YES', value: 'YES'}, {label: 'NO', value: 'NO'}]
		this.rejected = [{label: '-/-', value: '-/-'}, {label: 'YES', value: 'YES'}, {label: 'NO', value: 'NO'}]
		this.proprietary = [{label: '-/-', value: '-/-'}, {label: 'Proprietary', value: 'Proprietary'}]
	}

	getColor(mycolor: string): string {
		switch (mycolor) {
			case '-/-':
				return 'white';
			case 'OK':
				return 'lightgreen';
			case 'N/A':
				return 'yellow';
			case 'NOK':
				return 'red';

		}
	}

}
