export class PswPartDetail {
	pswPartDetailID: number;
	ppapPackagePartsEntityID : number;
	ppapPackageIdentifierID : number;
	custPartName: string;
	programCode: string;
	custPartNumber: string;
	drawingNumber: string;
	orgPartNumber: string;
	engChangeLvl: string;
	engChangeLvlDate: string;
	addEngChanges: string;
	addEngChangesDate: string;
	safetyRegulations: string;
	poNumber: string;
	weight: string;
	checkAidNumber: string;
	checkAidEngChangeLvl: string;
	checkAidEngChangeLvlDate: string;
	orgName: string;
	supplierCode: string;
	street: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	custDivision: string;
	buyerCode: string;
	application: string;
	custConcernReport: string;
	imds: string;
	polymerPartsISO: string;
	submissionReason: string;
	otherReason: string;
	reqSubmitLvl: any;
	submitResultFor: string;
	meetDesignReq: string;
	designReqExpln: string;
	moldCavityProdProc: string;
	paReqSource: any;
	paReqSrcDetail: string;
	paReqSrcDate: string;
	paReqAPW: number;
	paReqMPW: number;
	paReqMetDate: string;
	aftrPaReqSource: any;
	aftrPaReqSrcDetail: string;
	aftrPaReqSrcDate: Date;
	aftrPaReqAPW: number;
	aftrPaReqMPW: number;
	aftrPaReqMetDate: Date;
	demonCpctyAPPC: number;
	demonCpctyMPPC: number;
	demonCpctyDate: string;
	prodRate: string;
	prodHours: string;
	prodStreams: string;
	comments: string;
	orgAuthSign: string;
	authPrintName: string;
	authSignDate: Date;
	authTitle: string;
	authPhone: string;
	authFax: string
	authEmail: string;
	custToolTagNumber: string;
	engAuthAlert: string;
	engAuthDesc: string;
	engAuthIncomplPpapReq: string;
	nextAuditPlan: any;
	isFormCompleted: any;
	interimPpapStatus: boolean;
	createdBy: string;
	createdOn: Date;
	lastUpdatedBy: string;
	lastUpdatedOn: Date;

	constructor(pswPartDetail: PswPartDetail) {
		Object.assign(this, pswPartDetail);
	}
}
