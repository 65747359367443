import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {PpapPhaseSection} from "../../models/ppap-phase-section";
import {PpapPhaseSectionService} from "../../services/ppap-phase-section.service";

@Component({
	selector: 'ppap-phase-section',
	templateUrl: './ppap-phase-section.component.html',
	styleUrls: ['./ppap-phase-section.component.scss']
})
export class PpapPhaseSectionComponent implements OnInit {
	phase: SelectItem[];
	sectionName: string;
	sortSequence: number;
	disableCreateButton: any;
	ppapSectionData: PpapPhaseSection;
	ppapSectionLoadedData: any[];
	loading: any;
	sectionList: any[]=[];
	phaseValue: any;
	clonedSection: { [sectionId: number]: PpapPhaseSection; } = {};
	sectionDataList: PpapPhaseSection[];
	phaseZero: any[]=[];
	phaseOne: any[]=[];
	phaseTwo: any[]=[];
	phaseThree: any[]=[];
	phaseThreeForUpdate: any[]=[];
	sequenceZero: any[]=[];
	sequenceOne: any[]=[];
	sequenceTwo: any[]=[];
	sequenceThree: any[]=[];
	sectionExistsZero: boolean;
	sectionExistsOne: boolean;
	sectionExistsTwo: boolean;
	sectionExistsThree: boolean;
	seqExistsZero: boolean;
	seqExistsOne: boolean;
	seqExistsTwo: boolean;
	seqExistsThree: boolean;
	oldactive: string;
	oldSection: string;
	oldSeq: number;

	constructor(private ppapPhaseService: PpapPhaseSectionService, private confirmationService: ConfirmationService,
				private messageService: MessageService) {

	}

	ngOnInit(): void {
		this.ppapSectionData = new PpapPhaseSection(this.ppapSectionData);
		this.phase = [
			{label: 'Phase 0', value: {id: 1, name: 'Phase 0', code: 'P1'}},
			{label: 'Phase 1', value: {id: 2, name: 'Phase 1', code: 'P2'}},
			{label: 'Phase 2', value: {id: 3, name: 'Phase 2', code: 'P3'}},
			{label: 'Phase 3', value: {id: 4, name: 'Phase 3', code: 'P4'}},
		];
		this.getAllData();
		this.sectionExistsZero = false; this.sectionExistsOne = false; this.sectionExistsTwo = false; this.sectionExistsThree = false;
		this.seqExistsZero = false; this.seqExistsOne = false; this.seqExistsTwo = false; this.seqExistsThree = false;
	}

	getAllData(){
		this.loading = true;
		this.phaseZero = []; this.phaseOne = []; this.phaseTwo=[]; this.phaseThree = [];
		this.sequenceZero = []; this.sequenceOne = []; this.sequenceTwo=[]; this.sequenceThree = [];
		this.ppapPhaseService.getAllData().subscribe(resp=>{
			this.loading = false;
			this.ppapSectionLoadedData=resp;
			this.sectionDataList = resp;
			for(let data of this.ppapSectionLoadedData){
				if(data.isActive === 'Yes'){
					data.isActive = true;
				} else {
					data.isActive = false;
				}
			}
			for(let data of this.ppapSectionLoadedData){
				if(data.phase === 'Phase 0'){
					this.phaseZero.push(data.sectionName.trim());
					this.sequenceZero.push(data.sortSequence);
				} else if (data.phase === 'Phase 1') {
					this.phaseOne.push(data.sectionName.trim());
					this.sequenceOne.push(data.sortSequence);
				} else if (data.phase === 'Phase 2') {
					this.phaseTwo.push(data.sectionName.trim());
					this.sequenceTwo.push(data.sortSequence);
				} else if (data.phase === 'Phase 3') {
					this.phaseThree.push(data.sectionName.trim());
					this.sequenceThree.push(data.sortSequence);
				}
			}
		});
	}

	onClose() {
		this.resetFields();
	}

	private resetFields() {
		this.phaseValue = null;
		this.sectionName = '';
		this.sortSequence = null;
	}

	onCreate() {
		for(let data of this.ppapSectionLoadedData){
			if(data.phase === 'Phase 0'){
				this.phaseZero.push(data.sectionName.trim());
				this.sequenceZero.push(data.sortSequence);
			} else if (data.phase === 'Phase 1') {
				this.phaseOne.push(data.sectionName.trim());
				this.sequenceOne.push(data.sortSequence);
			} else if (data.phase === 'Phase 2') {
				this.phaseTwo.push(data.sectionName.trim());
				this.sequenceTwo.push(data.sortSequence);
			} else if (data.phase === 'Phase 3') {
				this.phaseThree.push(data.sectionName.trim());
				this.sequenceThree.push(data.sortSequence);
			}
		}
		this.sectionExistsZero = false; this.sectionExistsOne = false; this.sectionExistsTwo = false; this.sectionExistsThree = false;
		this.seqExistsZero = false; this.seqExistsOne = false; this.seqExistsTwo = false; this.seqExistsThree = false;
		if (this.phaseValue === null ||  this.phaseValue === undefined || this.sectionName === undefined || this.sectionName === '' ||
			this.sortSequence === undefined || this.sortSequence === null) {
			this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Please Fill the Required Fields!', life: 5000});
		}
		if (this.phaseValue !== undefined && (this.sectionName !== undefined && this.sectionName !== '') &&
			(this.sortSequence !== undefined && this.sortSequence !== null)) {
			if (this.phaseValue.name === 'Phase 0') {
				if (this.phaseZero.includes(this.sectionName)) {
					this.sectionExistsZero = true;
					this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Phase and Section Name combination already exists!', life: 5000});
				} else {
					this.sectionExistsZero = false;
					if (this.sequenceZero.includes(this.sortSequence)) {
						this.seqExistsZero = true;
						this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
					} else {
						this.seqExistsZero = false;
					}
				}
			} else if (this.phaseValue.name === 'Phase 1') {
				if (this.phaseOne.includes(this.sectionName)) {
					this.sectionExistsOne = true;
					this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Phase and Section Name combination already exists!', life: 5000});
				} else {
					this.sectionExistsOne = false;
					if (this.sequenceOne.includes(this.sortSequence)) {
						this.seqExistsOne = true;
						this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
					} else {
						this.seqExistsOne = false;
					}
				}
			} else if (this.phaseValue.name === 'Phase 2') {
				if (this.phaseTwo.includes(this.sectionName)) {
					this.sectionExistsTwo = true;
					this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Phase and Section Name combination already exists!', life: 5000});
				} else {
					this.sectionExistsTwo = false;
					if (this.sequenceTwo.includes(this.sortSequence)) {
						this.seqExistsTwo = true;
						this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
					} else {
						this.seqExistsTwo = false;
					}
				}
			} else if (this.phaseValue.name === 'Phase 3') {
				if (this.phaseThree.includes(this.sectionName)) {
					this.sectionExistsThree = true;
					this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Phase and Section Name combination already exists!', life: 5000});
				} else {
					this.sectionExistsThree = false;
					if (this.sequenceThree.includes(this.sortSequence)) {
						this.seqExistsThree = true;
						this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
					} else {
						this.seqExistsThree = false;
					}
				}
			}
			if (!this.sectionExistsZero && !this.sectionExistsOne && !this.sectionExistsTwo && !this.sectionExistsThree
				&& !this.seqExistsZero && !this.seqExistsOne && !this.seqExistsTwo && !this.seqExistsThree) {
				if (this.sectionName.length !== 0 && this.sortSequence > 0) {
					this.ppapSectionData.phase = this.phaseValue.name;
					this.ppapSectionData.sectionName = this.sectionName.trim();
					this.ppapSectionData.sortSequence = this.sortSequence;
					this.loading = true;
					this.ppapPhaseService.saveData(this.ppapSectionData).subscribe(resp => {
						this.loading = false;
						this.resetFields();
						this.getAllData();
					});
				} else {
					this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Please Fill the Required Fields!', life: 5000});
				}
			}
		}
	}

	onRowEditCancel(ppapSectionLoadedData: PpapPhaseSection, index: number) {
		if(this.clonedSection[ppapSectionLoadedData.sectionId] !== undefined) {
			this.sectionDataList[index] = this.clonedSection[ppapSectionLoadedData.sectionId];
			delete this.clonedSection[ppapSectionLoadedData.sectionId];
		}
		this.getAllData();
	}

	onRowDelete(ppapSectionLoadedData:PpapPhaseSection) {
		this.confirmationService.confirm({
			message: 'Deleting the section will also delete the associated questions. Are you sure you want to proceed?',
			header: 'Delete Confirmation',
			icon: 'fa fa-info-circle',
			accept: () => {
				this.loading = true;
				this.ppapPhaseService.deleteData(ppapSectionLoadedData.sectionId).subscribe(resp => {
					this.ppapSectionLoadedData = this.ppapSectionLoadedData.filter(i => i.sectionId !== resp);
					this.loading = false;
					this.getAllData();
				});
			},
			reject: () => {
			}
		});
	}

	onRowEditInit(ppapSectionLoadedData: PpapPhaseSection) {
		this.clonedSection[ppapSectionLoadedData.sectionId] = {...ppapSectionLoadedData};
		this.oldactive = ppapSectionLoadedData.isActive;
		this.oldSection = ppapSectionLoadedData.sectionName;
		this.oldSeq = ppapSectionLoadedData.sortSequence;
		this.phaseZero = []; this.phaseOne = []; this.phaseTwo =[]; this.phaseThree =[];
		this.sequenceZero = []; this.sequenceOne =[]; this.sequenceTwo = []; this.sequenceThree = [];
		for(let data of this.ppapSectionLoadedData){
			if(data.phase === 'Phase 0'){
				this.phaseZero.push(data.sectionName.trim());
				this.sequenceZero.push(data.sortSequence);
			} else if (data.phase === 'Phase 1') {
				this.phaseOne.push(data.sectionName.trim());
				this.sequenceOne.push(data.sortSequence);
			} else if (data.phase === 'Phase 2') {
				this.phaseTwo.push(data.sectionName.trim());
				this.sequenceTwo.push(data.sortSequence);
			} else if (data.phase === 'Phase 3') {
				this.phaseThree.push(data.sectionName.trim());
				this.sequenceThree.push(data.sortSequence);
			}
		}
		this.phaseZero = this.phaseZero.filter(i=>i!==ppapSectionLoadedData.sectionName);
		this.sequenceZero = this.sequenceZero.filter(i=>i!==ppapSectionLoadedData.sortSequence);
		this.phaseOne = this.phaseOne.filter(i=>i!==ppapSectionLoadedData.sectionName);
		this.sequenceOne = this.sequenceOne.filter(i=>i!==ppapSectionLoadedData.sortSequence);
		this.phaseTwo = this.phaseTwo.filter(i=>i!==ppapSectionLoadedData.sectionName);
		this.sequenceTwo = this.sequenceTwo.filter(i=>i!==ppapSectionLoadedData.sortSequence);
		this.phaseThree = this.phaseThree.filter(i=>i!==ppapSectionLoadedData.sectionName);
		this.sequenceThree = this.sequenceThree.filter(i=>i!==ppapSectionLoadedData.sortSequence);
	}

	onRowEditSave(ppapSectionLoadedData: PpapPhaseSection) {
		this.sectionExistsZero = false; this.sectionExistsOne = false; this.sectionExistsTwo = false; this.sectionExistsThree = false;
		this.seqExistsZero = false; this.seqExistsOne = false; this.seqExistsTwo = false; this.seqExistsThree = false;
		if (this.oldSection === ppapSectionLoadedData.sectionName && this.oldSeq === ppapSectionLoadedData.sortSequence &&
			this.oldactive === ppapSectionLoadedData.isActive) {
			this.messageService.add({key: 'ppapSectionToast',severity: 'info',summary: 'Information',detail: 'No Changes!',life: 5000
			});
		} else {
			if (ppapSectionLoadedData.phase === 'Phase 0') {
				if (this.phaseZero.includes(ppapSectionLoadedData.sectionName)) {
					this.sectionExistsZero = true;
					this.messageService.add({key: 'ppapSectionToast', severity: 'error',summary: 'Error',detail: 'Phase and Section Name combination already exists!', life: 5000});
				} else {
					this.sectionExistsZero = false;
					if (this.sequenceZero.includes(ppapSectionLoadedData.sortSequence)) {
						this.seqExistsZero = true;
						this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
					} else {
						this.seqExistsZero = false;
					}
				}
			} else if (ppapSectionLoadedData.phase === 'Phase 1') {
				if (this.phaseOne.includes(ppapSectionLoadedData.sectionName)) {
					this.sectionExistsOne = true;
					this.messageService.add({key: 'ppapSectionToast', severity: 'error',summary: 'Error',detail: 'Phase and Section Name combination already exists!', life: 5000});
				} else {
					this.sectionExistsOne = false;
					if (this.sequenceOne.includes(ppapSectionLoadedData.sortSequence)) {
						this.seqExistsOne = true;
						this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
					} else {
						this.seqExistsOne = false;
					}
				}
			} else if (ppapSectionLoadedData.phase === 'Phase 2') {
				if (this.phaseTwo.includes(ppapSectionLoadedData.sectionName)) {
					this.sectionExistsTwo = true;
					this.messageService.add({key: 'ppapSectionToast', severity: 'error',summary: 'Error',detail: 'Phase and Section Name combination already exists!', life: 5000});
				} else {
					this.sectionExistsTwo = false;
					if (this.sequenceTwo.includes(ppapSectionLoadedData.sortSequence)) {
						this.seqExistsTwo = true;
						this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
					} else {
						this.seqExistsTwo = false;
					}
				}
			} else if (ppapSectionLoadedData.phase === 'Phase 3') {
				if (this.phaseThree.includes(ppapSectionLoadedData.sectionName)) {
					this.sectionExistsThree = true;
					this.messageService.add({key: 'ppapSectionToast', severity: 'error',summary: 'Error',detail: 'Phase and Section Name combination already exists!', life: 5000});
				} else {
					this.sectionExistsThree = false;
					if (this.sequenceThree.includes(ppapSectionLoadedData.sortSequence)) {
						this.seqExistsThree = true;
						this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
					} else {
						this.seqExistsThree = false;
					}
				}
			}
			if (!this.sectionExistsZero && !this.sectionExistsOne && !this.sectionExistsTwo && !this.sectionExistsThree
				&& !this.seqExistsZero && !this.seqExistsOne && !this.seqExistsTwo && !this.seqExistsThree) {
				if (ppapSectionLoadedData.isActive || ppapSectionLoadedData.isActive === 'Yes') {
					ppapSectionLoadedData.isActive = 'Yes';
				} else {
					ppapSectionLoadedData.isActive = 'No';
				}
				if (ppapSectionLoadedData.sectionName.length!==0 && ppapSectionLoadedData.sortSequence > 0) {
				delete this.clonedSection[ppapSectionLoadedData.sectionId];
					this.loading = true;
					this.ppapPhaseService.updateData(ppapSectionLoadedData).subscribe(resp => {
						this.loading = false;
						this.getAllData();
					});
				} else {
					this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Please Fill the Required Fields!', life: 5000});
				}
			} else {
				if(ppapSectionLoadedData.sectionName.length===0 && ppapSectionLoadedData.sortSequence < 0){
					this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Please Fill the Required Fields!', life: 5000});
				} else {
					this.getAllData();
				}
			}
		}
	}
}
