import {
	Component,
	Inject,
	OnInit
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {environment} from '../../environments/environment';

/** This component handles redirecting users to the auth provider */
@Component({
	template: `
		<h3>Redirecting to login server</h3>
	`
})
export class LoginComponent implements OnInit {

	/** the configured URL endpoint to send the user's browser to for token negotiation */
	_endPoint: string;
	/** reference to the window object */
	_window: Window;

	private tokenUrl = environment.tokenUrl;
	private clientId = environment.clientId;
	private resource = environment.resource;

	/** Creates a new instance of the LoginComponent, makes available
	 * a DOCUMENT reference and instantiates required variables
	 *
	 * @param document angular provided reference to the current document
	 */
	constructor(@Inject(DOCUMENT) private document: Document) {
		this._window = this.document.defaultView;
		this._endPoint = this.tokenUrl +
			'?client_id=' + this.clientId +
			'&resource=' + this.resource +
			'&response_type=token+id_token'+
			'&redirect_uri=' + encodeURIComponent(window.location.origin + '/');
	}

	/** once the component is up and running, redirect the user to the auth provider */
	ngOnInit() {
		console.log('LoginComponent : trying to get token....');
		console.log(this._endPoint);
		//window.alert(this._endPoint);
		this._window.location.replace(this._endPoint);
	}
}
