export class LdapResponse {
	firstName?: string;
	lastName?: string;
	userId?: string;
	title?: any;
	departmentName?: any;
	mail?: string;
	telephoneNumber?: string;
	fordDisplayName?: string;
	fordDesignation?: string;
	name?: string;

	constructor(ldapResponse: LdapResponse) {
		Object.assign(this, ldapResponse);
	}
}
