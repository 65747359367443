import {PpapPhaseSection} from "./ppap-phase-section";

export class PpapPhaseQuestion {
	phase: string;
	questionId: number;
	sectionId: number;
	sectionName: string
	questionDesc: string;
	sortSequence: number;
	isActive: string;
	isMandatory: string;
	createdBy: string;
	createdOn: Date;
	lastUpdatedBy: string;
	lastUpdatedOn: Date;

	constructor(ppapPhaseQuestion: PpapPhaseQuestion) {
		Object.assign(this, ppapPhaseQuestion);
	}

}
