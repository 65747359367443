import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng';
import {SelectItem} from 'primeng/api';

interface PartNumberDetail {

	name: string;
	code: string;
}

interface Ppapdetail {
	//ppapcategory: string;
	pgm: string;
	prefixBaseSuffix: string;
	// base: string;
	// suffix: string;
	partdescription: string;
	changeintiming: string;
	rardt: string;
	rcheckout: string;
	qualdt: string;
	qcheckout: string;
	proddt: string;
	pcheckout: string;
	capdt: string;
	// status: string;
	// level: string;

}

@Component({
	selector: 'ppap-parts',
	templateUrl: './ppap-parts.component.html',
	styleUrls: ['./ppap-parts.component.scss'],
	providers: [DialogService]
})

export class PpapPartsComponent implements OnInit {
	addData: any = {};
	styles =
		{
			pgm: '100px',
			prefixBaseSuffix: '170px',
			partdescription: '250px',
			rardt: '95px',
			qualdt: '95px',
			proddt: '95px',
			capdt: '95px'
		}
	ppapTabDetail: Ppapdetail[];
	cols: any;
	partNumbersOption: SelectItem[];
	partNumbers: PartNumberDetail[];

	constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
		this.partNumbers = [
			{name: 'AB39 – 2122345 – AE', code: 'NY'},
			{name: 'AB39 – 2122346 – AE', code: 'RM'},
			{name: 'AB39 – 2122347 – AE', code: 'LDN'},
			{name: 'AB39 – 2122348 – AE', code: 'IST'},
			{name: 'AB39 – 2122349 – AE', code: 'PRS'}
		];
		this.partNumbersOption = [
			{
				label: 'AB39 – 2122345 – AE',
				value: null
			},
			{
				label: 'AB39 – 2122346 – AE',
				value: {id: 1, name: 'Part1', code: 'Part1'}
			},
			{
				label: 'AB39 – 2122347 – AE',
				value: {id: 1, name: 'Part2', code: 'Part2'}
			},
			{
				label: 'AB39 – 2122348 – AE',
				value: {id: 1, name: 'Part2', code: 'Part2'}
			},
			{
				label: 'AB39 – 2122349 – AE',
				value: {id: 1, name: 'Part2', code: 'Part2'}
			}
		];
		//table
		this.cols = [
			//{field: 'ppapcategory', header: 'PPAP CATEGORY'},
			{field: 'pgm', header: 'PGM'},
			{field: 'prefixBaseSuffix', header: 'PREFIX-BASE-SUFFIX'},
			// {field: 'suffix', header: 'BASE'},
			// {field: 'base', header: 'SUFFIX'},
			{field: 'partdescription', header: 'PART DESCRIPTION'},
			// { field: 'changeintiming', header: 'CHANGE IN TIMING' },
			{field: 'rardt', header: 'RAR DT'},
			// { field: 'rcheckout', header: 'CHECKOUT' },
			{field: 'qualdt', header: 'QUAL DT'},
			// { field: 'qcheckout', header: 'CHECKOUT' },
			{field: 'proddt', header: 'PROD DT'},
			// { field: 'pcheckout', header: 'CHECKOUT' },
			{field: 'capdt', header: 'CAPDT'}
			// // ,
			// {field: 'status', header: 'STATUS'},
			// {field: 'level', header: 'PPAP LEVEL'}
		];

		//table
		this.ppapTabDetail = [
			{
				//ppapcategory: 'All PPAP',
				pgm: '3691',
				prefixBaseSuffix: 'AB39 - 2122345 - AE',
				// base: 'AE',
				// suffix: '',
				partdescription: 'HGA ASY FRT DT HGA ASY FRT DT HGA ASY FRT DT HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'PENDING',
				// level: 'Level 3'
			},
			{
				//ppapcategory: 'Lunch / Running Change PPAP',
				pgm: '3692',
				prefixBaseSuffix: 'AB39 - 2122346 - AE',
				// base: 'AE',
				// suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'PENDING',
				// level: 'Level 3'
			},
			{
				//ppapcategory: 'Supplier Requested Change',
				pgm: '3693',
				prefixBaseSuffix: 'AB39 - 2122347 - AE',
				// base: 'AE',
				// suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'NEEDS CLARIFICATION',
				// level: 'Level 3'
			},
			{
				//ppapcategory: 'Annual Re-PPAP',
				pgm: '3694',
				prefixBaseSuffix: 'AB39 - 2122348 - AE',
				// base: 'AE',
				// suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'PENDING',
				// level: 'Level 3'
			},
			{
				//ppapcategory: 'All PPAP',
				pgm: '3691',
				prefixBaseSuffix: 'AB39 - 2122349 - AE',
				// base: 'AE',
				// suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'PENDING',
				// level: 'Level 3'
			},
			{
				//ppapcategory: 'Lunch / Running Change PPAP',
				pgm: '3692',
				prefixBaseSuffix: 'AB39 - 2122350 - AE',
				// base: 'AE',
				// suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'InProgress',
				// level: 'Level 3'
			},
			{
				//ppapcategory: 'Supplier Requested Change',
				pgm: '3693',
				prefixBaseSuffix: 'AB39 - 2122351 - AE',
				// base: 'AE',
				// suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'InProgress',
				// level: 'Level 3'
			},
			{
				//ppapcategory: 'Annual Re-PPAP',
				pgm: '3694',
				prefixBaseSuffix: 'AB39 - 2122352 - AE',
				// base: 'AE',
				// suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'AWAITING APPROVAL',
				// level: 'Level 3'
			},
			{
				//ppapcategory: 'All PPAP',
				pgm: '3691',
				prefixBaseSuffix: 'AB39 - 2122353 - AE',
				// base: 'AE',
				// suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'AWAITING APPROVAL',
				// level: 'Level 3'
			},
			{
				//ppapcategory: 'Lunch / Running Change PPAP',
				pgm: '3692',
				prefixBaseSuffix: 'AB39 - 2122356 - AE',
				// base: 'AE',
				// suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'PENDING',
				// level: 'Level 3'
			},
			{
				//ppapcategory: 'Supplier Requested Change',
				pgm: '3693',
				prefixBaseSuffix: 'AB39 - 2122357 - AE',
				// base: 'AE',
				// suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18'
				// ,
				// status: 'PENDING',
				// level: 'Level 3'
			}
		];
	}

	ngOnInit(): void {
	}

	addFieldValue() {
		this.addData = {
			//ppapcategory: '',
			pgm: '',
			prefixBaseSuffix: '',
			// base: 'AE',
			// suffix: '2122346-',
			partdescription: '',
			changeintiming: '  ',
			rardt: '',
			rcheckout: '  ',
			qualdt: '',
			qcheckout: '  ',
			proddt: '',
			pcheckout: '  ',
			capdt: ''
		}
		this.ppapTabDetail.unshift(this.addData);
		this.ppapTabDetail = this.ppapTabDetail;
	}

	deleteRow(x: any) {
		console.log(x);
		//var delBtn = confirm(" Do you want to delete ?");
		//if (delBtn === true) {
		this.ppapTabDetail.splice(x, 1);
	}

}
