import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent {

  constructor() { }

	goToUrl(): void {
		window.open('https://www.accessmgmt.ford.com/', '_blank');
	}
}
